import {Link} from 'gatsby'
import React from 'react'

import styles from './contact-block.module.css'

function ContactBlock ({header, content}) {
  return (
    <div className={styles.root}>
    <span className={styles.blockHeader}>
        {header}
    </span>
    <span className="text-larger">{content}</span>
</div>
  )
}
ContactBlock.defaultProps = {
    header: '',
    text: '',
  }
export default ContactBlock
