import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes } from '../lib/helpers';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';

import ContactBlock from '../components/contact-block';

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      subtitle
      description
      keywords
    }
  }
`;

const ContactPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const galleryNodes = (data || {}).imageSets ? mapEdgesToNodes(data.imageSets) : [];

  const aboutUsGallery = galleryNodes[0];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="Kontakt oss" description={site.description} keywords={site.keywords} />
      <Container>
        <h1>Kontakt oss</h1>
        <div class="row wrap">
          <ContactBlock header="Besøk" content="Ulefossvegen 42 ved Herkules i Skien" />
          <ContactBlock header="Ring oss" content="35 90 55 40" />
          <ContactBlock header="E-Post" content="post@guntorinterior.no" />
        </div>
        <div class="map-responsive">
          <iframe
            allowfullscreen
            src="https://maps.google.com/maps?q=Guntor%20Interi%C3%B8r&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </Container>
    </Layout>
  );
};

export default ContactPage;
